
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import ChatCallType from '@/components/shared/chat/call/ChatCallType.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import type { DetailsTableItemType } from '@/definitions/shared/types'
import type { ModalNames } from '@/components/modals/types'

export default defineComponent({
  components: {
    TmModal,
    TmPerson,
    TmCountry,
    TmStatus,
    DetailsTable,
    ChatCallType,
  },
  props: {
    modalId: {
      type: String as PropType<ModalNames>,
      default: 'chatsMessageDetails',
    },
    items: {
      type: Array as PropType<DetailsTableItemType[]>,
    },
    cancelButtonText: {
      type: String,
    },
    messageBubble: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const hasFooterSlot = !!context.slots['modal-footer-button']

    return {
      hasFooterSlot,
    }
  },
})
