import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ac5cbb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "my-n1"
}
const _hoisted_2 = {
  key: 0,
  class: "my-n1"
}
const _hoisted_3 = {
  key: 0,
  class: "message-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_chat_call_type = _resolveComponent("chat-call-type")!
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_details_table = _resolveComponent("details-table")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: _ctx.modalId,
    size: "large",
    "no-footer": !_ctx.hasFooterSlot,
    "cancel-button-text": _ctx.cancelButtonText
  }, _createSlots({
    "modal-content": _withCtx(() => [
      _createVNode(_component_details_table, { items: _ctx.items }, {
        status: _withCtx(({ value }) => [
          _createVNode(_component_tm_status, {
            name: value.name,
            outline: value.outline,
            class: "ma-0",
            color: value.class
          }, null, 8, ["name", "outline", "color"])
        ]),
        direction: _withCtx(({ value }) => [
          _createVNode(_component_chat_call_type, {
            outcoming: value.outcoming,
            "call-type": value.callType
          }, null, 8, ["outcoming", "call-type"])
        ]),
        country: _withCtx(({ value }) => [
          _createVNode(_component_tm_country, {
            country: value,
            size: "xSmall"
          }, null, 8, ["country"])
        ]),
        to: _withCtx(({ value }) => [
          (value.fullName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_tm_person, {
                  name: value.fullName,
                  "avatar-color": value.avatarColor,
                  "avatar-url": value.avatar
                }, null, 8, ["name", "avatar-color", "avatar-url"])
              ]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(value), 1)
              ], 64))
        ]),
        from: _withCtx(({ value }) => [
          (value.fullName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_tm_person, {
                  name: value.fullName,
                  "avatar-color": value.avatarColor,
                  "avatar-url": value.avatar
                }, null, 8, ["name", "avatar-color", "avatar-url"])
              ]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(value), 1)
              ], 64))
        ]),
        "message-content": _withCtx(({ value }) => [
          (_ctx.messageBubble)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(value), 1))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(value), 1)
              ], 64))
        ]),
        _: 1
      }, 8, ["items"])
    ]),
    _: 2
  }, [
    (_ctx.hasFooterSlot)
      ? {
          name: "modal-footer-button",
          fn: _withCtx(({ hideModal }) => [
            _renderSlot(_ctx.$slots, "modal-footer-button", _normalizeProps(_guardReactiveProps({ hideModal })), undefined, true)
          ])
        }
      : undefined
  ]), 1032, ["id", "no-footer", "cancel-button-text"]))
}